<template>
    <Layout class="subLayout">
        <Sider
            ref="navigation"
            class="sider"
            breakpoint="sm"
            hide-trigger
            collapsible
            :collapsed-width="0"
        >
            <layout-logo></layout-logo>
            <Menu
                class="leftmenu"
                :active-name="this.$route.name"
                theme="light"
                width="auto"
            >
                <MenuGroup v-for="(item, index) in navigation" :key="index" :title="$t(`navigation.${item.meta.title}`)">
                    <MenuItem
                        v-for="(sub, subindex) in item.children"
                        :name="sub.name"
                        :key="subindex"
                        @click.native="openPages(sub.name)"
                    >
                        <!-- <Icon :type="sub.meta.icon" :size="20" />{{ $t(sub.meta.title) }} -->
                        <i :class="['iconfont', sub.meta.icon]"></i>{{ $t(sub.meta.title) }}
                    </MenuItem>
                </MenuGroup>
            </Menu>
        </Sider>
        <Layout class="wrapper">
            <layout-header></layout-header>
            <Content class="layout-content">
                <div class="overflow">
                    <company-title></company-title>
                    <router-view></router-view>
                    <record-footer></record-footer>
                </div>
            </Content>
        </Layout>
    </Layout>  
</template>
<script>
    import recordFooter from './components/recordFooter'
    import companyTitle from './components/companyTitle'
    import layoutHeader from './components/layoutHeader'
    import layoutLogo from './components/layoutLogo'

    export default {
        name: "",
        mixins: [],
        components: {
            recordFooter,
            companyTitle,
            layoutHeader,
            layoutLogo
        },
        props: [],
        data () {
            return {}
        },
        computed: {
            navigation() {
                return this.$store.getters.menus(['personalCenter'])
            }
        },
        methods: {
            openPages(e){
                this.$router.replace({ name: e })
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
</style>